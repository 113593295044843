import React from 'react';
import { SocialIcon } from 'react-social-icons'

export const Footer = () => {
  return (
     <div className='flex bg-blue-300 text-blue-950 w-full'>
        <div className='max-w-[100rem] mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex items-center justify-between h-fit'>
                <div className='flex flex-row w-full justify-between items-center'>
                    <div className='text-xl font-bold font-mono pr-2 xl:pr-[90vh]'>
                        West Metro Writing Institute
                    </div>
                    <div className='space-x-2 my-2 xl:space-x-4 justify-center items-center flex'>
                    <SocialIcon url="https://www.instagram.com/westmetro_writinginstitute/" bgColor="DodgerBlue"/>
                    <SocialIcon url="https://www.facebook.com/West%2520Metro%2520Writing%2520Institute" bgColor="DodgerBlue"/>
                    <SocialIcon url="https://www.youtube.com/@WestMetroWritingInstitute" bgColor="DodgerBlue"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}