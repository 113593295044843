import {NavBar}from '../components/navbar';
import { Footer } from '../components/footer';
import { Analytics } from "@vercel/analytics/react"

export default function AboutUs() {
  window.setTimeout(function() {
    window.scrollTo(0,0); 
}, 0);


  return (
    <div>
     <NavBar ></NavBar>
    <div className='relative'>
        <div className='place-items-center w-full  min-h-screen bg-gradient-to-r from-yellow-200 via-rose-400 to-blue-300 h-full'>
            <div className='grid grid-cols-1 sm:grid-cols-2 place-items-center w-full min-h-fit py-14 text-blue-950 text-center'>
                <div>
                    <h1 className='text-4xl lg:text-6xl font-bold font-mono'>Our Story</h1>
                </div>
                <p className='lg:pr-56 text-sm lg:text-xl font-mono font-medium text-left w-[90%] mt-2'>
                        The West Metro Writing Institute offers services to help students craft compelling essays. Our experienced, licensed English teachers guide students individually or in groups to develop their strong, authentic voices into stand-out essays.
                </p>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 place-items-center w-full min-h-fit py-14 text-blue-950 text-center'>
                <div>
                    <h1 className='text-4xl lg:text-6xl font-bold font-mono'>Our Mission</h1>
                </div>
                <p className='lg:pr-56 text-sm lg:text-xl font-mono font-medium text-left w-[90%] mt-2'>
                        The West Metro Writing Institute offers services to help students craft compelling essays. Our experienced, licensed English teachers guide students individually or in groups to develop their strong, authentic voices into stand-out essays.
                </p>
            </div>
        </div>
    </div>
    <Footer />
    <Analytics />
    </div>
  );
}