import React from 'react';
import hamburger from '../assets/img/hamburger.svg'
import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false)
    let {pathname}=useLocation()
    let page=pathname.split("/")?.[1]

    function Linkness(type=null){
        if(page===""){
            page='home'
        }
        let NavLinkStyles = 'border-blue-950 font-bold text-sm px-2 py-1 border-2 hover:bg-blue-950 hover:text-gray-100 transition duration-300 rounded-lg'

        if(type===page){
            NavLinkStyles+=" text-gray-100 bg-blue-950"
        }else{
            NavLinkStyles+=" text-blue-950 bg-gray-100"
        }

        return NavLinkStyles
    }

    const NavLinks = (
        <>
            <NavLink to='/home' className={Linkness('home')}>Home</NavLink>
            <NavLink to='/register' className={Linkness('register')}>Register</NavLink>
            <NavLink to='/aboutus' className={Linkness('aboutus')}>About Us</NavLink>
            <NavLink to='/coaches' className={Linkness('coaches')}>Coaches</NavLink>
        </>
    )

    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const onScroll = () => {
          if (window.scrollY > 50) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
    
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
      }, []);

  return (
    <>
     <div className={`fixed z-50 bg-blue-300 text-blue-950 w-full ${scrolled ? ' bg-blue-300': ''}`}>
        <div className='max-w-[100rem] mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex items-center justify-between h-16'>
                <div className='flex flex-row w-full justify-between'>
                    <div className='text-xl font-bold font-mono'>
                        West Metro Writing Institute
                    </div>
                    <div className='hidden md:block'>
                        <div className='flex ml-10 items-baseline space-x-2 w-full '>
                            {NavLinks}
                        </div>
                    </div>
                </div>
                <div className='md:hidden'>
                    <button onClick={()=>{setIsOpen(!isOpen)}} type='button'>
                        <img src={hamburger} className='max-w-7' />
                    </button>
                </div>
            </div>
        </div>
        {
            isOpen && (
                <div className='flex flex-col gap-y-2 md:hidden px-4 sm:px-6 pb-2'>
                    {NavLinks}
                </div>
            )
        }
     </div>
     <div className='pt-16'>
     {/* This div adds padding to ensure the content below the navbar is visible */}
   </div>
   </>
  )
}