import {NavBar} from '../components/navbar';
import {Hero} from '../components/hero';
import {HomePricing} from '../components/homepricing.js';
import { Footer } from '../components/footer';
import { Analytics } from "@vercel/analytics/react"

export default function Home() {
  window.setTimeout(function() {
    window.scrollTo(0,0); 
}, 0);


  return (
    <div>
     <NavBar ></NavBar>
     <Hero></Hero>
    <HomePricing />
    <Footer />
    <Analytics />
    </div>
  );
}