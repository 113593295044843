import {NavBar} from '../components/navbar';
import { Footer } from '../components/footer';
import { Analytics } from "@vercel/analytics/react"

export default function Contact() {
  window.setTimeout(function() {
    window.scrollTo(0,0); 
}, 0);


  return (
    <div className="App">
     <NavBar ></NavBar>
     Contact
     <Footer />
     <Analytics />
    </div>
  );
}