import React from 'react';
import close from '../assets/img/red-x-icon.png';
import DownArrow from '../assets/img/downarrow.svg'

export const Modal = ({ isVisible, onClose, name, text, image }) => {
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === 'wrapper') onClose();
  };

  return (
    <div className='fixed inset-0 bg-opacity-30 backdrop-blur-sm flex pt-16 sm:pt-0 sm:items-center justify-center z-10 h-screen' id='wrapper' onClick={handleClose}>
      <div className='w-[100%] sm:w-[80%] flex flex-col place-items-center overflow-hidden'>
        <div className='bg-white p-2 rounded-lg max-w-[100%] overflow-y-auto h-[100vh] sm:h-fit relative'>
          <div className='my-4 sm:my-10'>
            <button
            className='absolute top-2 right-2 hover:scale-110 transition duration-300'
            onClick={() => onClose()}
          >
            <img src={close} className='w-8' />
          </button>
            </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-7 w-full min-h-[600px] items-center'>
            <div>
            <div className='text-blue-950 xl:max-h-[60vh] xl:overflow-scroll'>
              <h1 className='text-4xl lg:text-6xl font-bold font-mono pb-4 pt-4 sm:pt-0 text-center'>{name}</h1>
              <p className='text-sm lg:text-xl font-mono font-medium px-2 text-left'>
                {text}
              </p>
            </div>
            <div className='justify-center items-center mt-4 sm:flex hidden text-xl font-mono font-semibold'>Scroll to Learn More <img src={DownArrow} className='w-8 ml-2'/></div>
            </div>
            <div className='place-items-center'>
              <img src={image} className='w-full rounded-xl inline-block mb-4' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
