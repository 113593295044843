import {NavBar} from '../components/navbar';
import { TeacherCards } from '../components/teacherCard';
import regnier from '../assets/img/regnierphoto.png'
import cardona from '../assets/img/cardonaphoto.png'
import byrd from '../assets/img/Byrd-Tracy.png'
import { Modal } from '../components/modal';
import { useState } from 'react';
import { Footer } from '../components/footer';
import { Analytics } from "@vercel/analytics/react"


export default function Teachers() {
  window.setTimeout(function() {
    window.scrollTo(0,0); 
}, 0);

  const [showModal, setShowModal]=useState(false)
  return (
    <div className="bg-gradient-to-br from-yellow-100 via-rose-100 to-blue-100">
     <NavBar ></NavBar>
     <h1 className='text-center text-blue-950 text-4xl lg:text-6xl font-bold font-mono pt-4'>Meet Our Professional Coaches</h1>
     <div className='grid grid-cols-1 sm:grid-cols-3 place-items-center w-full min-h-[80vh] bg-gradient-to-br from-yellow-100 via-rose-100 to-blue-100'>
     <TeacherCards image={regnier} name='Colleen Regnier' position='Founder and Coach' more={
    <div>
      <p className="mb-4">
        With 26 years in education and expertise in AP and IB writing frameworks, my mission is to create equitable pathways for all students to access educational resources aligned with their personal and professional goals.
      </p>
      <p className="mb-4">
        My work in the classroom has ignited a profound passion for students' success. I find fulfillment in guiding students, from high school age to second-career, toward success through active listening and shared stories.
      </p>
      <p className="mb-2 font-bold">What you will gain from my partnership:</p>
      <ul className="list-disc ml-5 space-y-2">
        <li>Accountability: Commitment to your professional growth and personal support.</li>
        <li>Professional Impact: Proven system to cultivate your unique writing voice + craft compelling content that showcases your expertise.</li>
        <li>Enthusiasm: A shared excitement for achieving your writing goals.</li>
      </ul>
      <p className="mt-4 mb-2 font-bold">Educational accomplishments:</p>
      <ul className="list-disc ml-5 space-y-2">
        <li>M.Ed. Educational Leadership - Georgia Southern University</li>
        <li>B. A. English Literature - Randolph-Macon Woman's College (now Randolph College)</li>
        <li>Multi-state teaching certifications - Hamline University, Armstrong Atlantic State University</li>
        <li>English Teacher at Wayzata High School</li>
        <li>Creative Writing Club Advisor at Wayzata High School</li>
        <li>Reading & Writing Tutor with 32 years experience, ages 8 to 92, all skill levels.</li>
      </ul>
    </div>
  } />
     <TeacherCards image={cardona} name='Ashley Cardona' position='Coach' more='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fermentum dui faucibus in ornare quam viverra orci sagittis. Consectetur a erat nam at lectus urna duis. Viverra vitae congue eu consequat ac felis donec. Purus sit amet volutpat consequat mauris nunc congue nisi. Porta non pulvinar neque laoreet suspendisse interdum. Mollis nunc sed id semper risus in hendrerit. Quis lectus nulla at volutpat diam ut. Convallis aenean et tortor at risus viverra adipiscing at. Amet mauris commodo quis imperdiet massa tincidunt nunc.
' />
     <TeacherCards image={byrd} name='Tracy Byrd' position='Coach' more={
    <div>
      <p className="mb-4">
      Being an educator for over 20 years revealed a deep passion with students. I thoroughly enjoy helping students realize their full potential, by listening to their stories and sharing my life experiences, through stories. 
      </p>
      <p className="mb-2 font-bold">What you will gain from my partnership:</p>
      <ul className="list-disc ml-5 space-y-2">
        <li>Accountability: I will be there for you and hold you accountable, while you hold me accountable, as a mentor.</li>
        <li>Professionalism: I will help you develop and  craft your professional voice within your writing.</li>
        <li>Enthusiasm: Writing is a process and I am excited to bring my passion of writing.  </li>
      </ul>
      <p className="mt-4 mb-2 font-bold">Educational accomplishments:</p>
      <ul className="list-disc ml-5 space-y-2">
        <li>Teacher of the Year - 2024 Minnesota</li>
        <li>Bachelor degree - English Teacher from Metropolitan State University</li>
        <li>Motivational speaker</li>
        <li>D2BR - Equity trainer</li>
        <li>English Teacher at Minneapolis Washburn High School</li>
      </ul>
    </div>
  }  />
     </div>
     <Footer />
     <Analytics />
    </div>
  );
}