import React from 'react';
import { Accordion } from './accordion';

export const RegisterAccordion = () => {
    return(
    <div className='py-2 px-3 lg:pl-5 bg-slate-100 rounded-lg border-blue-950 border-2'>
        <div className='border-b-[3px] border-blue-950'><Accordion title='1-Day Essay' description='Get your essay from brainstorm to publication with full support! This group session with a professional coach that ensures originality with no AI or plagiarism risks. Craft your essay in 3 hours for just $200.'/></div>
        <div className='border-b-[3px] border-blue-950'><Accordion title='1-to-1 Coaching' description='Receive personalized coaching on ACT prep, LinkedIn resumes, letters, and creative writing publications. This individual session offers tailored guidance with a dedicated coach for $60 per hour.'/></div>
        <div><Accordion title='Coming Soon...' description="Exciting workshops are coming soon! Join us for specialized sessions like LinkedIn and ACT prep workshops, and prepare for Novel November. Stay tuned for more details!"/></div>
    </div>
)
}