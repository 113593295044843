import React from 'react';
import editing from '../assets/img/essay-edititing.jpg'

export const Hero = () => {
    return<>
    <div className='relative'>
        <div className='place-items-center w-full bg-gradient-to-r from-yellow-200 via-rose-400 to-blue-300'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-7 place-items-center w-full min-h-[600px]'>
                <div className='space-y-7 text-blue-950 text-left mx-12 my-8'>
                    <h1 className='text-4xl lg:text-6xl font-bold font-mono'>Transforming Words Into Success</h1>
                    <p className='lg:pr-56 text-sm lg:text-xl font-mono font-medium'>
                        The West Metro Writing Institute offers services to help students craft compelling essays. Our experienced, licensed English teachers guide students individually or in groups to develop their strong, authentic voices into stand-out essays.
                    </p>
                </div>
                <div className='place-items-center'>
                    <img src={editing} className='rounded-3xl max-w-[95%] mb-8 mx-3'/>
                </div>
            </div>
        </div>
    </div>
    </>
}