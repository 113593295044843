import React from 'react';
import { PricingCards } from '../components/pricingcards';
import group from '../assets/img/group-meeting.png'
import hourly from '../assets/img/hourly-help.png'
import oneday from '../assets/img/one-day-essay.png'


export const HomePricing = () => {
    return<>
    <div className='grid grid-cols-1 sm:grid-cols-3 place-items-center w-full min-h-[600px] bg-gradient-to-br from-yellow-100 via-rose-100 to-blue-100 pt-4'>
        <PricingCards
            title='1-Day Essay'
            description={
    <div>
      <ul className="list-disc ml-5 space-y-2">
        <li>From brainstorm to publication</li>
        <li>College App + Scholarship Essays</li>
        <li>No AI or plagiarism risk</li>
        <li>Group session with coach</li>
      </ul>
    </div>
  }
            image={hourly}
            link= '/register'
            duration='3 Hours'
            buttonText='Register'
            cost='$200'>
        </PricingCards>
        <PricingCards
            title='1-to-1 Coaching'
            description={
    <div>
      <ul className="list-disc ml-5 space-y-2">
        <li>ACT prep</li>
        <li>LinkedIn resumes & letters</li>
        <li>Creative Writing publications </li>
        <li>Individual session with coach</li>
      </ul>
    </div>
  }
            image={oneday}
            link= '/register'
            duration='Hourly'
            buttonText='Register'
            cost='$60 per hour'>
        </PricingCards>
        <PricingCards
            title='Coming Soon...'
            description={
    <div>
      <ul className="list-disc ml-5 space-y-2">
        <li>LinkedIn workshop</li>
        <li>ACT prep workshop</li>
        <li>Novel November workshop</li>
      </ul>
    </div>
  }
            image={group}
            link= '/register'
            duration='Hourly'
            buttonText='Register'
            cost='$40 per person'>
        </PricingCards>
    </div>
    </>
}