import {NavBar} from '../components/navbar';
import { InlineWidget } from "react-calendly";
import { RegisterAccordion } from '../components/registeraccordion';
import { Footer } from '../components/footer';
import { Analytics } from "@vercel/analytics/react"


export default function Register() {
  window.setTimeout(function() {
    window.scrollTo(0,0); 
}, 0);


  return (
    <div>
     <NavBar ></NavBar>
     <div className='bg-gradient-to-br from-yellow-100 via-rose-100 to-blue-100'>
      <h1 className='text-center text-blue-950 text-4xl lg:text-6xl font-bold font-mono pt-4'>Learn More About Our Services!</h1>
      <div className='p-4 lg:px-44'>
        <RegisterAccordion />
      </div>
      <InlineWidget url="https://calendly.com/wmwi" styles={{height:'700px'}}/>
     </div>
     <Footer />
     <Analytics />
    </div>
  );
}