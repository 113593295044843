import React from 'react';
import { useState } from 'react';

export const Accordion = ({title,description}) => {
    const [isOpen, setIsOpen] = useState(false)

  return (
     <div className='py-2'>
        <button className={`flex justify-between w-full hover:opacity-100 font-mono ${isOpen ? 'opacity-100' : 'opacity-60'}`} onClick={()=>setIsOpen(!isOpen)}>
            <span>{title}</span>
            <svg
          className="fill-blue-950 shrink-0 ml-8"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              isOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              isOpen && "!rotate-180"
            }`}
          />
        </svg>
        </button>
        <div className={`grid overflow-hidden transition-all duration-400 ease-in-out ${isOpen ? 'grid-rows-[1fr] opacity-100 mt-2' : 'grid-rows-[0fr] opacity-0'}`}>
            <div className={`overflow-hidden border-t-2 border-black ${isOpen ? 'pt-2' : ''}`}>{description}</div>
        </div>
     </div>
  )
}