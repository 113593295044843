import React from 'react';
import {Link, useLocation } from 'react-router-dom';

export const PricingCards = ({title,description,image,link,buttonText,cost,duration}) => {
    return(
    <div className='flex flex-col w-[20rem] h-fit bg-gradient-to-br from-yellow-200 via-rose-300 to-blue-200 rounded-2xl place-items-center shadow-2xl hover:scale-[105%] duration-500 mb-4'>
        <img src={image} className='w-[30%] my-[5%]'/>
        <h1 className='text-3xl font-semibold text-blue-950 relative text-center inline-flex place-items-center w-fit border-b-2 border-black font-mono'>{title}</h1>
        
        <p className='text-lg font-medium text-blue-950 relative text-left inline-flex place-items-center w-[80%]  font-mono'> Description:</p>
        <p className='text-lg font-medium text-blue-950 relative text-left inline-flex place-items-center w-[80%] border-b-2 border-black font-mono'>{description}</p>
        <p className='text-lg font-medium text-blue-950 relative text-left inline-flex place-items-center w-[80%] border-b-2 border-black font-mono'>Duration: {duration}</p>
        <p className='text-lg font-medium text-blue-950 relative text-left inline-flex place-items-center w-[80%] border-b-2 border-black font-mono'>Cost: {cost}</p>
        <Link to={link} className='border-blue-950 font-bold text-sm px-3 py-2 border-4 my-2 hover:bg-blue-950 hover:text-gray-100 transition duration-300 rounded-lg font-mono'>
        {buttonText}</Link>
    </div>)
}