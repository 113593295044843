import React from 'react';
import {Modal} from '../components/modal'
import { useState } from 'react';

export const TeacherCards = ({name,image,position,more}) => {
    const [showModal, setShowModal]=useState(false)

    return(
    <div>
    <div className='flex flex-col w-[20rem] h-fit bg-gradient-to-br from-yellow-200 via-rose-300 to-blue-200 rounded-2xl place-items-center shadow-2xl hover:scale-[105%] duration-500 mb-8 hover:cursor-pointer' onClick={()=>setShowModal(true)}>
        <img className='inline-flex place-items-center overflow-hidden rounded-t-2xl' src={image} />
        <p className='text-lg font-medium text-blue-950 relative text-left inline-flex place-items-center w-[80%] border-b-2 border-black font-mono pt-2'>{name}</p>
        <p className='text-lg font-medium text-blue-950 relative text-left inline-flex place-items-center w-[80%] border-b-2 border-black font-mono z-0'>{position}</p>
        <button className='border-blue-950 font-bold text-sm px-3 py-2 border-4 my-2 hover:bg-blue-950 hover:text-gray-100 transition duration-300 rounded-lg font-mono'
        onClick={()=>setShowModal(true)}>
        Learn More!</button>
    </div>
    <Modal isVisible={showModal} onClose={()=>setShowModal(false)} name={name} text={more} image={image}/>
    </div>
    )
}