import logo from './logo.svg';
import './App.css';
import NavBar from './components/navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './pages/home'
import AboutUs from './pages/aboutus'
import Register from './pages/register'
import Teachers from './pages/teachers'
import Contact from './pages/contact'

function App() {
  return (
<div>
  <BrowserRouter>
    <Routes>
      <Route index element={<Home />} />
      <Route path="/home" element={<Home/>} />
      <Route path="/aboutus" element={<AboutUs/>} />
      <Route path="/register" element={<Register/>} />
      <Route path="/coaches" element={<Teachers/>} />
      <Route path="/contact" element={<Contact/>} />
    </Routes>
  </BrowserRouter>
</div>
  );
}

export default App;
